<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Полвонниёз ҳожи Юсупов <br />(1861-1936)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
          <b-card-text>
          <p class="TextBlack17">
            Ўзбек халқининг тараққийпарвар сиймоларидан, Хива жадидлари ва "Ёш
            хиваликлар" партиясининг раҳбарларидан бири Полвонниёз ҳожи Юсупов
            1861 йили Хива шаҳрида савдогар оиласида туғилган. 1871 йилга қадар
            ота-онасининг, улар вафотидан кейин еса амакиси Юсуфбойнинг қўлида
            тарбияланган. 13 ёшида ески мактабда ўқиб, савод чиқарган. Мактабни
            биткизгач, савдо ишлари билан шуғулланган. Полвонниёз 1877 йилда
            амакиси билан Москвада бўлади. Кейинчалик 1885 йилда жадид мактабини
            тугатади. У араб, форс ва рус тилларини яхши билган. 1904 йилда
            Туркия, Миср, Сурия мамлакатларида ва Макка шаҳрида бўлади.
            Полвонниёз 1917 йил 4 апрелда Хива хонига тақдим етилган давлат
            бошқарувида ислоҳотлар ўтказиш тўғрисидаги манифестнинг
            тузувчиларидан бири еди. Хива хонининг жадидларга қарши сиёсати
            натижасида у 1918—1920 йилларда Тошкентга ўтиб кетади ва у йерда
            муҳожирликда бўлиб, "Ёш хиваликлар" партиясини ташкил қилади ва унга
            раис етиб сайланади. Хива хонлиги тугатилгач, 1920 йил 30 апрелда
            Хоразм Халқ Совет Республикаси Нозирлар кенгаши раиси лавозимига
            тайинланади. Aсфандиёрхоннинг жадидларга қарши сиёсати натижасида
            Юсупов 1917 йил охиридан бошлаб 2 йил давомида Тошкентда яшаган.
            Болшевиклар таъсиридаги Ёш хиваликлар инқилобий қўмитаси раиси
            (1918-йил бошидан). Туркистон AССР Миллий ишлар халқ комиссарлигида
            Туркман шўбаси бошлиғи (1919). Хива хонлиги ағдарилгач (1920-йил 1
            фев.), янги ташкил қилинган Хоразм Халқ Совет Республикаси Нозирлар
            Шўросининг биринчи раиси (1920-йил 27 апр. — 1921-йил 6 март).
            Полвонниёз Юсупов ХХСР ҳукумати раиси сифатида мустақил сиёсат
            юритиш ва Хоразм Республикасида демократик ислоҳотларни қарор
            топтириш учун интилган. Бироқ Туркистон комиссияси (Турккомиссия) ва
            Туркистон фронти қўмондонлиги Полвонниёз Юсуповнинг бу сиёсатига
            қарши чиқиб, Хивада янги ҳукумат тўнтаришини амалга оширишган.
            Полвонниёз Юсупов фаол сиёсий курашдан воз кечганлиги учун
            кейинчалик болшевиклар томонидан афв қилинган ва ХХСРда турли
            лавозимларда хизмат қилган. 1926 йил 10 январдан Хоразм вилоят
            Ижроия қўмитаси раисининг хўжалик бўйича ўринбосари бўлиб ишлайди.
            Кейинчалик Ичанқалъа музcйида хизмат қилади. Юсупов ХХСР ҳукумати
            раиси сифатида мустақил сиёсат юритиш ва Хоразм Республикасида
            демократик ислоҳотларни қарор топтириш учун интилган. Бироқ
            Туркистон комиссияси (Турккомиссия) ва Туркистон фронти қўмондонлиги
            Юсуповнинг бу сиёсатига қарши чиқиб, Хивада янги ҳукумат тўнтаришини
            амалга оширишган. Юсупов фаол сиёсий курашдан воз кечганлиги учун
            кейинчалик болшевиклар томонидан афв қилинган ва ХХСРда турли
            лавозимларда хизмат қилган. Полвонниёз Юсупов 1936 йилда вафот
            етади.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
        
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
